import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery} from 'gatsby';
import Image from 'gatsby-image';

import { color, mixin } from '@src/settings';

import Hero from '@components/en/common/hero';
import ContactIntro from '@components/en/common/contact-intro';

import Hero__mds from '@img/svg/flow/hero__mds.svg';

import Layout from '@components/common/layout';
import FlowItem from '@components/flow/flow-item';
import { Container, PageIntro } from '@components/flow/styles';

const FlowPage = ({ data }) => (
	<Layout baseBgColor={ color.paleGray }>
		<Hero
			img={ data["Hero_img_200311"] }
			title={<Hero__mds />}
		/>

		<PageIntro
			title="The Effective IKKANSEISAN System"
			subtitle="From the Development to Production of parts, all process are accomplished within the same factory. We are the only company in Malaysia that specialized in IKKANSEISAN System, enable us to achieve Good Quality, Short Lead Time, Competitive Cost."
		/>
		<Container>
			<FlowItem
				className="flowItem"
				title="Analysis / SE"
				cap="In order to achieve shorter development lead time, we do Simultaneous Engineering and CAE Analysis."
				img={ data["Flow01"] }
				isFirst={ true }
			/>

			<FlowItem
				className="flowItem"
				title="Prototype"
				cap="We develop prototyping to support customer in testing, analysis and evaluation."
				img={ data["Flow03"] }
			/>

			<FlowItem
				className="flowItem"
				title="Manufacturing Process Planning"
				cap="We establish the most suitable Manufacturing Process for mass production."
				img={ data["Flow03_200311"] }
			/>

			<FlowItem
				className="flowItem"
				title="Equipment Design"
				cap="We design Dies, Checking Fixtures and Jigs based on the best Process Plan."
				img={ data["Flow02"] }
			/>

			<FlowItem
				className="flowItem"
				title="Equipment Fabrication"
				cap="Dies, Checking Fixtures and Jigs are fabricated in house with top quality"
				img={ data["Flow05_200311"] }
			/>

			<FlowItem
				className="flowItem"
				title="Pallet production"
				cap="We design and fabricate customized pallet,  so that finished goods are kept and transported in good quality."
				img={ data["Flow05"] }
			/>

			<FlowItem
				className="flowItem"
				title="Press"
				cap="Metal Stamping to produce good quality parts by using the right process."
				img={ data["Flow07_200311"] }
			/>

			<FlowItem
				className="flowItem"
				title="Assembly"
				cap="Parts are assembled by using the Automated Wedling Robot."
				img={ data["Flow07"] }
			/>

			<FlowItem
				className="flowItem"
				title="Inspection"
				cap="Parts are inspected to ensure quality follows the drawing."
				img={ data["Flow09_200311"] }
			/>

			<FlowItem
				className="flowItem"
				title="Delivery"
				cap="Logistics service to deliver high quality parts to customers On-time."
				img={ data["Flow09"] }
			/>
		</Container>
		<ContactIntro />
	</Layout>
)

export default FlowPage

export const query = graphql`
	query flowImgs_en{

		Hero_img_200311: file( relativePath: { eq: "flow/hero-img_200311.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Flow01: file( relativePath: { eq: "flow/flow01.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Flow03: file( relativePath: { eq: "flow/flow03.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Flow03_200311: file( relativePath: { eq: "flow/flow03_200311.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Flow02: file( relativePath: { eq: "flow/flow02.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Flow05_200311: file( relativePath: { eq: "flow/flow05_200311.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Flow05: file( relativePath: { eq: "flow/flow05.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Flow07_200311: file( relativePath: { eq: "flow/flow07_200311.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Flow07: file( relativePath: { eq: "flow/flow07.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Flow09_200311: file( relativePath: { eq: "flow/flow09_200311.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Flow09: file( relativePath: { eq: "flow/flow09.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

	},
`;
